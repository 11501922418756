import PrivacyPage from "./system/_privacyPage";

export default PrivacyPage

export const frontmatter = {
  pageMetaData: {
    refKey: "privacy",
    title: "Privacy Policy - We respect your privacy",
    description: "Your privacy is important to us. We respect your privacy regarding any information we may collect from you across our website.",
    author: "simplebackups.com"
  }
}